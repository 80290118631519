import React from "react";
import {
  StyledBody,
  StyledSection,
  StyledArticle,
  Row,
} from "styles/Containers";
import SEO from "components/seo";
import { StyledH1, StyledH3, P } from "styles/Typography";
import { SharedLayout } from "layouts/SharedLayout";

class Terms extends React.Component {
  render() {
    return (
      <SharedLayout>
        <StyledH1> Terms and Conditions </StyledH1>
        <SEO title="Terms" />
        <StyledSection>
          <StyledArticle>
            <StyledH3>1. Disclaimer:</StyledH3>
            <P>
              All information on “the site” (realsafecars.com, and subpages) are
              provided for informational purposes only. All data and materials
              displayed on the site are provided “as is.” RealSafeCars, LLC,
              including principals, owners, agents, and employees offer no
              warranties or guaranties of any kind in regard to the accuracy of
              the data and materials.
            </P>
            <br />
            <br />
            <StyledH3>2. License to Display Our Ratings</StyledH3>
            <P>
              On the terms set forth in this agreement, “the user” (you) is
              granted a perpetual and royalty free (non- exclusive,
              non-assignable, non-transferable, non-sublicensable) license to
              use (download, store) ratings and materials that pertain to “the
              site’s” (RealSafeCars.com) safety ratings and probabilities of
              death and display them on any consumer-facing website or
              application subject to the condition of proper attribution of the
              source of the materials. Proper attribution is defined as
              providing a link to “the site” (realsafecars.com), referencing
              “RealSafeCars” ratings, and not misrepresenting the information as
              your own including if the materials are displayed or utilized. We
              reserve the right to change the terms of service at any time, but
              changes to the terms of service will not apply to materials “the
              user” had acquired from “the site” prior to the change. You may
              also use and display “the site’s” mark (logo) in reference to “the
              site’s” materials.
            </P>
            <P>
              The user agrees not to assert copyright, trademark, or other
              intellectual property ownership or other property rights to
              RealSafeCars materials. This agreement shall not be construed to
              grant you any right, title, or interest in the property of
              RealSafeCars, LLC. You agree not to register any trade name or
              trademark that is confusingly similar to RealSafeCars.
            </P>
          </StyledArticle>
        </StyledSection>
      </SharedLayout>
    );
  }
}

export default Terms;
